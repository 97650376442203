import PayrollProvisionalApiCommon from '@jetCommon/api/payroll-provisional.js';

export default class PayrollProvisionalApi extends PayrollProvisionalApiCommon {
    approve(id) {
        return this.apiPost(`${id}/approve/`);
    }

    reopenForEditing(id) {
        return this.apiPost(`${id}/reopen_for_editing/`);
    }

    askChanges(id, data) {
        return this.apiPost(`${id}/ask_changes/`, data);
    }
}
