import {ApiBase} from '@jetCommon/api/base.js';

export default class CompanyShiftPresetApiCommon extends ApiBase {
    static resourceName = 'company_shift_preset';

    colorChoices() {
        return this.apiGet('color_choices/');
    }

    addressesChoices(params) {
        return this.apiGet('addresses_choices/', {params});
    }
}
