import {
    durationFromTotalMinutes,
    formatDuration,
    formatTimeInterval,
    parseTimeIntervalString,
} from '@jetCommon/helpers/time';
import {ANOMALY_KIND} from '@jetCommon/constants/clocking-constants.js';
import {daysDifference} from '@jetCommon/helpers/date';
import {formatDistance} from '@jetCommon/helpers/geolocalization';
import {formatDuration as standardFormatDuration} from '@jetCommon/helpers/time.js';

const formatAnomalyMessage = anomaly => {
    switch (anomaly.kind) {
        case ANOMALY_KIND.MISSING:
            return 'Mancante';
        case ANOMALY_KIND.UNEXPECTED:
            return 'Non prevista';
        case ANOMALY_KIND.CLOCKING_TIMING:
            if (anomaly.delta < 0) {
                return `${formatDuration(durationFromTotalMinutes(Math.abs(anomaly.delta)), {seconds: false, showMinutesIfNoHours: true})} di anticipo`;
            }

            return `${formatDuration(durationFromTotalMinutes(anomaly.delta), {seconds: false, showMinutesIfNoHours: true})} di ritardo`;
        case ANOMALY_KIND.WORKDAY_HOURS:
            if (anomaly.delta < 0) {
                return `${formatDuration(durationFromTotalMinutes(Math.abs(anomaly.delta)), {seconds: false, showMinutesIfNoHours: true})} in meno`;
            }

            return `${formatDuration(durationFromTotalMinutes(anomaly.delta), {seconds: false, showMinutesIfNoHours: true})} in in più`;
        case ANOMALY_KIND.MISSING_GEOLOCATION:
            return 'Posizione mancante';
        case ANOMALY_KIND.GEOLOCATION_OUTSIDE_RANGE:
            return `${formatDistance(anomaly.delta)} dalla sede`;
    }
};

function formatWorkedTimeText(workedMinutes) {
    return formatDuration(durationFromTotalMinutes(workedMinutes), {showMinutesIfNoHours: true});
}

function dailyClockingSurrogateKey(employeeId, date) {
    if (date === 'today') {
        return date;
    }

    return `${employeeId}-${date}`;
}

function computeSupString(isoDate1, isoDate2) {
    if (!isoDate1 || !isoDate2) {
        return '';
    }

    const daysDiff = daysDifference(isoDate1, isoDate2);
    if (daysDiff > 0) {
        return `+${daysDiff}`;
    }
    if (daysDiff < 0) {
        return daysDiff;
    }
    return '';
}

function toSuperscript(numberStr) {
    // Define superscript equivalents for digits 0-9
    const superscriptDigits = {
        0: '⁰',
        1: '¹',
        2: '²',
        3: '³',
        4: '⁴',
        5: '⁵',
        6: '⁶',
        7: '⁷',
        8: '⁸',
        9: '⁹',
        '+': '⁺',
        '-': '⁻',
    };

    // Convert each character in the string to its superscript form
    return Array.from(numberStr)
        .map(char => superscriptDigits[char] || char)
        .join('');
}

function getAbsenceRequestFormatComponents(absenceRequest, refDate) {
    if (!absenceRequest.time_duration) {
        return {
            from: '00:00',
            fromSup: '',
            to: '00:00',
            toSup: '+1',
            duration: '24h',
        };
    }

    const from = formatTimeInterval(parseTimeIntervalString(absenceRequest.start_time), {seconds: false});
    const to = formatTimeInterval(parseTimeIntervalString(absenceRequest.end_time), {seconds: false});

    const {minutes, hours} = durationFromTotalMinutes(absenceRequest.leave_hours_in_seconds / 60);

    const duration = standardFormatDuration({hours, minutes}, {showMinutesIfNoHours: true});
    return {
        from,
        fromSup: computeSupString(absenceRequest.start_date, refDate),
        to,
        toSup: computeSupString(absenceRequest.end_date, refDate),
        duration,
    };
}

export {
    formatAnomalyMessage,
    formatWorkedTimeText,
    dailyClockingSurrogateKey,
    computeSupString,
    getAbsenceRequestFormatComponents,
    toSuperscript,
};
