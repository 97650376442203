import {ABSENCE_KINDS_GROUPED, ABSENCE_KIND_GROUPS} from '@/features/company-policy/absence/constants';

export function useAbsencePolicyOptions(policyId) {
    const {$api} = inject('jet');
    const absenceOptions = ref([]);
    const updatedOptions = ref([]);
    const route = useRoute();
    const companyId = computed(() => route.params.companyId);

    const initialActiveOptions = ref([]);
    const initialApprovalRequiredOptions = ref([]);

    function _getSortedOptionsFromGroup(group) {
        const availableOptions = ABSENCE_KINDS_GROUPED[group];
        return (
            absenceOptions.value
                .filter(choice => availableOptions.includes(choice.codename) && choice.can_be_used_by_company)
                // UI needs a specific ordering specified in the ABSENCE_KINDS_GROUPED mapping
                .sort((a, b) => availableOptions.indexOf(a.codename) - availableOptions.indexOf(b.codename))
        );
    }

    const absenceOptionsGrouped = computed(() => {
        if (!absenceOptions.value.length) {
            return null;
        }
        const presenceChoices = _getSortedOptionsFromGroup(ABSENCE_KIND_GROUPS.PRESENCE);
        const holidaysAndPermitsChoices = _getSortedOptionsFromGroup(ABSENCE_KIND_GROUPS.HOLIDAYS_AND_PERMITS);
        const leavesChoices = _getSortedOptionsFromGroup(ABSENCE_KIND_GROUPS.LEAVE);
        const customChoices = absenceOptions.value.filter(choice => choice.is_custom && choice.can_be_used_by_company);

        return {
            [ABSENCE_KIND_GROUPS.PRESENCE]: presenceChoices,
            [ABSENCE_KIND_GROUPS.HOLIDAYS_AND_PERMITS]: [...holidaysAndPermitsChoices, ...customChoices],
            [ABSENCE_KIND_GROUPS.LEAVE]: leavesChoices,
        };
    });

    function fetchAbsenceRequestKinds() {
        const isNewPolicy = !policyId;
        const apiMethod = isNewPolicy ? 'getDefaultConfiguration' : 'list';
        const queryParams = isNewPolicy ? {company: companyId.value} : {policy: policyId, scope: 'policy_settings'};

        $api.absenceRequestKindSettings[apiMethod](queryParams)
            .then(response => {
                absenceOptions.value = response.data;

                if (isNewPolicy) {
                    updatedOptions.value = response.data;
                }

                initialActiveOptions.value = response.data
                    .filter(option => option.is_active)
                    .map(option => option.codename);

                initialApprovalRequiredOptions.value = response.data
                    .filter(option => option.requires_approval)
                    .map(option => option.codename);
            })
            .catch($api.end);
    }

    function onUpdateOption(newData) {
        const currentChanges = updatedOptions.value;
        const isInPendingChanging = currentChanges.some(option => option.codename === newData.codename);

        if (isInPendingChanging) {
            updatedOptions.value = currentChanges.map(option =>
                option.codename === newData.codename ? newData : option,
            );
        } else {
            updatedOptions.value = [...currentChanges, newData];
        }
    }

    fetchAbsenceRequestKinds();

    watch(
        absenceOptions,
        () => {
            if (!absenceOptions.value.length) {
                return;
            }

            initialActiveOptions.value = [
                ...absenceOptions.value.filter(option => option.is_active).map(option => option.codename),
            ];

            initialApprovalRequiredOptions.value = [
                ...absenceOptions.value.filter(option => option.requires_approval).map(option => option.codename),
            ];
        },
        {once: true},
    );

    return {
        absenceOptionsGrouped,
        onUpdateOption,
        updatedOptions,
        initialActiveOptions,
        initialApprovalRequiredOptions,
    };
}
