<script setup>
    import {isoDatetimeToItaDatetime} from '@jetCommon/helpers/date.js';
    import {openFile} from '@jetCommon/helpers/files.js';
    import {useOnboarding} from '@/features/onboarding/composables/onboarding.js';

    import FileList from '@jetCommon/components/uploads/FileList.vue';
    import OnboardingTaskItem from '@/features/onboarding/components/tasks/base/OnboardingTaskItem.vue';
    import UploadedFileUploadBox from '@jetCommon/components/UploadedFileUploadBox.vue';

    const props = defineProps({
        codename: {
            type: String,
            required: true,
        },
        hasTemplate: {
            type: Boolean,
            default: false,
        },
        modalTitle: {
            type: String,
            default: null,
        },
        actions: {
            type: Array,
            default: () => [],
        },
        description: {
            type: String,
            default: null,
        },
        templateConfirmButtonText: {
            type: String,
            default: null,
        },
        templateActionButtonText: {
            type: String,
            default: 'Scarica',
        },
        title: {
            type: String,
            required: true,
        },
    });

    const {$api, $jetMessage} = inject('jet');
    const {fetchAll, taskByCodename} = useOnboarding();
    const task = computed(() => taskByCodename.value[props.codename]);
    const templateFileUrl = computed(() => props.hasTemplate && task.value.setup_data?.template_file_url);
    const uploadBoxFileType = computed(() => {
        return (task.value.setup_data?.file_extensions || []).map(ext => `.${ext}`).join(',');
    });
    const uploadBoxTipText = computed(() => {
        const fileExtensions = (task.value.setup_data?.file_extensions || []).map(ext => ext.toUpperCase()).join(',');
        return `${fileExtensions} - Massimo 10 MB`;
    });

    const actions = computed(() => {
        const uploadFile = {
            main: true,
            text: 'Carica',
            dialog: {
                title: props.modalTitle || props.title,
                name: 'upload',
                show_confirm_button: false,
            },
        };
        const downloadTemplateFile = {
            text: props.templateActionButtonText,
            dialog: {
                title: props.title,
                name: 'template',
                confirm_button_text: props.templateConfirmButtonText,
                confirm_callback: () => {
                    openFile(templateFileUrl.value);
                },
            },
        };

        return [...(props.hasTemplate ? [downloadTemplateFile] : []), uploadFile];
    });

    const fileList = computed(() => {
        return task.value.documents.map(doc => ({
            display_name: doc.filename,
            href: doc.download_url,
            caption: `Caricato il ${isoDatetimeToItaDatetime(doc.created_at)}`,
            approval_status: doc.approval_status,
        }));
    });

    function onFileUploaded(uploadedFileId) {
        $api.companyOnboardingTaskDocuments
            .create({
                company_onboarding_task: task.value.id,
                uploaded_file: uploadedFileId,
            })
            .then(() => {
                fetchAll();
                $jetMessage.success('File caricato con successo');
            })
            .catch($api.end);
    }
</script>

<template>
    <OnboardingTaskItem :actions="actions" :codename="codename" :description="description" :title="title">
        <template #dialogTemplateBody>
            <slot name="dialogTemplateBody" />
        </template>
        <template #dialogUploadBody>
            <slot name="dialogUploadBodyPre" />
            <UploadedFileUploadBox
                :upload-size-limit="10 * 1024 * 1024"
                button-text="Carica file"
                :show-file-list="false"
                :tip-text="uploadBoxTipText"
                :upload-file-type="uploadBoxFileType"
                :upload-limit="9999"
                @file-uploaded="onFileUploaded" />
            <p class="OnboardingTaskItem_FileListTitle mb-2 mt-4">File Caricati</p>
            <FileList :file-list="fileList" show-approval-icon show-separator />
        </template>
    </OnboardingTaskItem>
</template>

<style lang="scss" scoped>
    .OnboardingTaskItem_FileListTitle {
        color: var(--jet-text-color-secondary);
        font-size: var(--jet-font-size-base);
        font-weight: var(--jet-font-weight-semibold);
    }
</style>
