import {inject} from 'vue';
import {useQuery} from '@tanstack/vue-query';

export const useCompanyAbsenceRequestKindsQuery = queryParams => {
    const {$api} = inject('jet');

    return useQuery({
        queryKey: ['getCompanyAbsenceRequestKinds', queryParams],
        queryFn: async () => {
            const response = await $api.companyAbsenceRequestKinds.list(toValue(queryParams)).catch($api.end);

            return response?.data ?? [];
        },
        placeholderData: [],
    });
};
