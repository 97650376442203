import {inject} from 'vue';
import {useQuery} from '@tanstack/vue-query';

export const useAbsenceRequestAttachmentChoicesQuery = () => {
    const {$api} = inject('jet');

    return useQuery({
        queryKey: ['getAbsenceRequestAttachmentChoices'],
        queryFn: async () => {
            const response = await $api.absenceRequestKindSettings.attachmentChoices().catch($api.end);

            return response?.data ?? [];
        },
        placeholderData: [],
    });
};
