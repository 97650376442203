import {
    ABSENCE_REQUEST_CODENAME_ICON_NAME,
    CODENAME_LABEL_MAPPING,
} from '@jetCommon/constants/absence-request-constants.js';
import {useAbsenceRequestAttachmentChoicesQuery} from '@jetCommon/queries/useAbsenceRequestAttachmentChoicesQuery';
import {useCompanyAbsenceRequestKindsQuery} from '@jetCommon/queries/useCompanyAbsenceRequestKindsQuery';

export function useCompanyAbsenceRequestKind(companyId, queryParams = {}) {
    const kindQueryParams = computed(() => {
        return {
            company: toValue(companyId),
            ...queryParams,
        };
    });

    const {data: companyAbsenceRequestKinds} = useCompanyAbsenceRequestKindsQuery(kindQueryParams);

    const {data: attachmentChoices} = useAbsenceRequestAttachmentChoicesQuery();

    const customCompanyAbsenceRequestKinds = computed(() => {
        if (!companyAbsenceRequestKinds.value || companyAbsenceRequestKinds.value.length === 0) {
            return [];
        }

        return companyAbsenceRequestKinds.value.filter(
            companyAbsenceRequestKind => companyAbsenceRequestKind.is_custom,
        );
    });

    /**
     * Given a codename returns the absence request kind display name
     * @param {object} absenceRequest the absence request for which we want to get the label
     * @returns {string}
     */
    function getAbsenceRequestLabel(absenceRequest) {
        return absenceRequest.kind_display || CODENAME_LABEL_MAPPING[absenceRequest.codename];
    }

    /**
     * Given a codename returns the icon name for a company absence request
     * @param {string} codename
     * @returns {string} icon name
     */
    function getIconForCompanyAbsenceRequestCodename(codename) {
        const isCustomCodename = customCompanyAbsenceRequestKinds.value.some(
            companyAbsenceRequestKind => companyAbsenceRequestKind.codename === codename,
        );

        if (isCustomCodename) {
            return 'calendar-clock';
        }

        return ABSENCE_REQUEST_CODENAME_ICON_NAME[codename] || 'clock-three';
    }

    return {
        companyAbsenceRequestKinds,
        customCompanyAbsenceRequestKinds,
        getAbsenceRequestLabel,
        attachmentChoices,
        getIconForCompanyAbsenceRequestCodename,
    };
}
