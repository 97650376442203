<script setup>
    import {requiredFieldRule} from '@jetCommon/helpers/validators.js';

    import JetForm from '@jetDS/components/JetForm.vue';
    import JetFormItem from '@jetDS/components/JetFormItem.vue';
    import JetRadio from '@jetDS/components/JetRadio.vue';
    import JetRadioGroup from '@jetDS/components/JetRadioGroup.vue';

    const props = defineProps({
        task: {
            type: Object,
            required: true,
        },
        readOnly: {
            type: Boolean,
            default: false,
        },
    });

    const emit = defineEmits(['submitted']);

    const {$api, $jetMessage} = inject('jet');
    const formRef = ref(null);

    const formData = reactive({
        has_terminated_employees: props.task.submitted_data?.has_terminated_employees ?? false,
    });

    const formRules = computed(() => ({
        has_terminated_employees: [requiredFieldRule],
    }));

    async function submit() {
        if (props.readOnly) {
            $jetMessage.warning('Non puoi modificare i dati in questo momento.');
            return;
        }

        const formEl = formRef.value.getElFormRef();
        const valid = await formEl.validate().catch(() => false);
        if (valid) {
            $api.companyOnboardingTasks
                .submitData(props.task.id, formData)
                .then(() => emit('submitted'))
                .catch($api.end);
        }
    }

    defineExpose({
        formData,
        submit,
    });
</script>

<template>
    <JetForm ref="formRef" :rules="formRules" :model="formData">
        <JetFormItem
            prop="has_terminated_employees"
            label="Hai delle persone che hanno cessato il rapporto di lavoro da dicembre dell'anno scorso fino al mese di partenza con Jet HR?">
            <JetRadioGroup v-model="formData.has_terminated_employees" :disabled="props.readOnly" vertical>
                <JetRadio :label="true">Sì</JetRadio>
                <JetRadio :label="false">No</JetRadio>
            </JetRadioGroup>
        </JetFormItem>
    </JetForm>
</template>
