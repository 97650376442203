import {dailyClockingSurrogateKey} from '@jetCommon/helpers/clocking';
import ClockingsApiCommon from '@jetCommon/api/clocking.js';

export default class ClockingApi extends ClockingsApiCommon {
    static resourceName = 'daily_clockings';

    payrollStats(companyId, payrollYear, payrollMonth) {
        return this.apiGet('payroll_stats/', {
            params: {company: companyId, payroll_year: payrollYear, payroll_month: payrollMonth},
        });
    }

    suggestResolution(companyId, dailyClockings) {
        return this.apiPost('suggest_resolution/', {company: companyId, daily_clockings: dailyClockings});
    }

    resolveAnomaliesWithRequest(companyId, date, employeeId, absenceRequestId) {
        return this.apiPost(`${dailyClockingSurrogateKey(employeeId, date)}/resolve_anomalies_with_request/`, {
            company: companyId,
            absence_request: absenceRequestId,
        });
    }
}
