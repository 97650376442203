import ContractVariationApiCommon from '@jetCommon/api/contract-variation.js';

export default class ContractVariationsApi extends ContractVariationApiCommon {
    start(data) {
        return this.apiPost('start/', data);
    }

    step(id, scope, data) {
        return this.apiPatch(`${id}/step/?scope=${scope}`, data);
    }

    firstValidVariationDate(companyId) {
        return this.apiGet(`first_valid_variation_date/?company_id=${companyId}`);
    }

    nextSignerLink(id) {
        return this.apiGet(`${id}/next_signer_link/`);
    }

    generateJetAgreement(id) {
        return this.apiPost(`${id}/generate_jet_agreement/`);
    }

    markAsDeleted(id) {
        return this.apiPost(`${id}/mark_as_deleted/`);
    }

    getCCNLPrefilledParameters(params) {
        return this.apiGet('required_ccnl_parameters/', {params});
    }
}
