import ContractVariationDocumentCommonApi from '@jetCommon/api/contract-variation-document.js';

export default class ContractVariationDocumentApi extends ContractVariationDocumentCommonApi {
    buildFromTemplate(variationDocument) {
        return this.apiPost('build_from_template/', variationDocument);
    }

    buildFromFile(variationDocument) {
        return this.apiPost('build_from_file/', variationDocument);
    }

    updateTemplateDocument(id, variationDocument) {
        return this.apiPatch(`${id}/update_template_document/`, variationDocument);
    }

    duplicateLastCompanyLogo(companyId) {
        return this.apiPost('duplicate_last_used_logo/', {company: companyId});
    }
}
