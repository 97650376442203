import {
    beforeEachHandler as onboardingBeforeEachHandler,
    routes as onboardingRoutes,
} from '@/features/onboarding/router.js';
import {ROUTER_KEYS} from '@/features/company-policy/config.js';
import {afterEachHandler, beforeEachHandler, commonRoutes, onError} from '@jetCommon/router.js';
import {api} from '@/api';
import {createRouter, createWebHashHistory} from 'vue-router';
import {scrollBehavior} from '@jetCommon/router.js';
import {useSpaStore} from '@/stores/spa.js';
import settings from '@/env.js';

const routes = [
    ...commonRoutes,
    ...onboardingRoutes,
    {
        path: '/registrazione/:specialLinkCode',
        component: () => import('@/views/FirstAccessView.vue'),
        meta: {requiresAuth: false},
        name: 'firstAccessView',
    },
    {
        path: `/:${settings.JET_ROUTE_ENTITY_NAME}/personale/assunzioni-variazioni`,
        component: () => import('@/layouts/SidebarLayout.vue'),
        children: [
            {
                path: ':tab(tab-assunzioni|tab-variazioni)',
                component: () => import('@/features/hirings-and-variations/views/HiringsAndVariationsView.vue'),
                name: 'hiringsAndVariationsViewWithTab',
            },
            {
                path: '',
                redirect: to => ({path: `${to.path}/tab-assunzioni`, replace: true}),
                name: 'hiringsAndVariationsView',
            },
            {
                path: 'variazione/:id?',
                component: () => import('@/features/variation/views/ContractVariationView.vue'),
                name: 'contractVariationView',
            },
        ],
    },
    {
        path: `/:${settings.JET_ROUTE_ENTITY_NAME}/personale/nuove-assunzioni`,
        component: () => import('@/layouts/SidebarLayout.vue'),
        children: [
            {
                path: 'dipendente/:id?',
                component: () => import('@/features/hiring/views/EmployeeHiringView.vue'),
                name: 'employeeHiringView',
            },
            {
                path: 'co-co-co/:id?',
                component: () => import('@/features/hiring/views/CollaboratorHiringView.vue'),
                name: 'collaboratorHiringView',
            },
            {
                path: 'nuovo',
                component: () => import('@/features/hiring/views/NewHiringView.vue'),
                name: 'newHiringView',
            },
            {
                path: ':tab(in_corso|completate)',
                component: () => import('@/features/hiring/views/HiringsView.vue'),
                name: 'hiringsViewWithTab',
            },
            {
                path: '',
                redirect: to => ({path: `${to.path}/in_corso`, replace: true}),
                name: 'hiringsView',
            },
            {
                path: 'clausole-allegati',
                component: () => import('@/features/hiring/views/ContractResourcesHiringView.vue'),
                name: 'hiringContractResourcesHiringView',
                children: [
                    {
                        path: ':tab(clausole|allegati)',
                        component: () => import('@/features/hiring/views/ContractResourcesHiringView.vue'),
                        name: 'hiringContractResourcesHiringView',
                    },
                ],
            },
            {
                path: 'clausola/:id?',
                component: () => import('@/features/hiring/views/ClauseView.vue'),
                name: 'hiringClauseView',
            },
            {
                path: 'allegati/:id?',
                component: () => import('@/features/hiring/views/AttachmentsHiringView.vue'),
                name: 'AttachmentsHiringView',
            },
        ],
    },
    {
        path: `/:${settings.JET_ROUTE_ENTITY_NAME}/assumi`,
        component: () => import('@/layouts/EmptyLayout.vue'),
        meta: {disableOnMobileWidths: true},
        children: [
            {
                path: '',
                component: () => import('@/features/hiring/views/retool/HiringView.vue'),
                name: 'hiringView',
            },
            {
                path: 'dipendente/tipologia/:typology',
                component: () => import('@/features/hiring/views/retool/HiringEmployeeView.vue'),
                name: 'hiringEmployeeView',
            },
            {
                path: 'cococo',
                component: () => import('@/features/hiring/views/retool/HiringCoCoCoView.vue'),
                name: 'hiringCoCoCoView',
            },
            {
                path: 'tirocinante',
                component: () => import('@/features/hiring/views/retool/HiringInternView.vue'),
                name: 'hiringInternView',
            },
            {
                path: 'amministratore',
                component: () => import('@/features/hiring/views/retool/HiringAdministrator.vue'),
                name: 'hiringAdministratorView',
            },
        ],
    },
    {
        path: `/:${settings.JET_ROUTE_ENTITY_NAME}?`,
        component: () => import('@/layouts/SidebarLayout.vue'),
        children: [
            {
                path: '',
                component: () => import('@/views/DashboardView.vue'),
                name: 'dashboardView',
            },
            {
                path: 'dipendenti/:tab(tab-in-forza|tab-in-arrivo|tab-contratto-terminato)',
                component: () => import('@/views/EmployeesView.vue'),
                name: 'employeesViewWithTab',
            },
            {
                path: 'dipendenti',
                redirect: to => ({path: `${to.path}/tab-in-forza`, replace: true}),
                name: 'employeesView',
            },
            {
                path: 'professionisti-esterni/:tab(tab-disponibili|tab-archiviati)',
                component: () => import('@/features/contractors/views/ContractorsView.vue'),
                name: 'contractorsViewWithTab',
            },
            {
                path: 'professionisti-esterni',
                redirect: to => ({path: `${to.path}/tab-disponibili`, replace: true}),
                name: 'contractorsView',
            },
            {
                path: 'professionisti-esterni/:contractorId(\\d+)',
                component: () => import('@/features/contractors/views/ContractorDetailView.vue'),
                name: 'contractorDetailView',
            },
            {
                path: 'professionisti-esterni/fatture',
                component: () => import('@/features/contractors/views/ContractorInvoicesView.vue'),
                name: 'contractorsInvoicesView',
            },
            {
                path: 'professionisti-esterni/fatture/:year(\\d{4})/:month(0?[1-9]|1[0-2])',
                component: () => import('@/features/contractors/views/ContractorInvoicesPaymentPeriodView.vue'),
                name: 'contractorsInvoicesMonthView',
            },
            {
                path: 'dipendenti/:employeeId(\\d+)',
                component: () => import('@/views/EmployeeDetailView.vue'),
                name: 'employeeDetailView',
            },
            {
                path: 'assenze/:tab(tab-da-approvare|tab-in-approvazione-manager|tab-approvate|tab-rifiutate)',
                component: () => import('@/views/AbsenceRequestsView.vue'),
                name: 'absenceRequestsViewWithTab',
            },
            {
                path: 'assenze',
                redirect: to => ({path: `${to.path}/tab-da-approvare`, replace: true}),
                name: 'absenceRequestsView',
            },
            {
                path: 'buoni-pasto/:period?',
                component: () => import('@/views/MealVouchersView.vue'),
                name: 'mealVouchersView',
            },
            {
                path: 'calendario-assenze',
                component: () => import('@/views/AbsenceCalendarView.vue'),
                name: 'absenceCalendarView',
            },
            {
                path: 'calendario-turni',
                component: () => import('@/features/shift/views/ShiftCalendarView.vue'),
                name: 'shiftCalendarView',
            },
            {
                path: 'calendario-turni/preset-salvati',
                component: () => import('@/features/shift/views/ShiftPresetView.vue'),
                name: 'shiftPresetView',
            },
            {
                path: 'timbrature',
                redirect: to => ({path: `${to.path}/corrente/tab-da-risolvere`, replace: true}),
                name: 'clockingsView',
            },
            {
                path: 'timbrature/:period/:tab(tab-tutte|tab-da-risolvere|tab-risolte|tab-in-gestione-manager|tab-contatori-periodici)',
                component: () => import('@/features/clocking/views/ClockingsView.vue'),
                name: 'clockingsViewWithTab',
            },
            {
                path: 'cedolini',
                component: () => import('@/views/PayrollsView.vue'),
                meta: {disableOnMobileWidths: true},
                name: 'payrollsView',
            },
            {
                path: 'cedolini/:payrollPeriod',
                component: () => import('@/views/PayrollDetailView.vue'),
                name: 'payrollDetailView',
            },
            {
                path: 'cedolini/:payrollPeriod/pagamenti',
                component: () => import('@/views/PayrollDetailPaymentsView.vue'),
                name: 'payrollDetailPaymentsView',
            },
            {
                path: 'report',
                redirect: {name: 'reportEmployeesView'},
            },
            {
                path: 'report/personale',
                component: () => import('@/views/ReportEmployeesView.vue'),
                meta: {disableOnMobileWidths: true},
                name: 'reportEmployeesView',
            },
            {
                path: 'report/ferie-e-permessi',
                component: () => import('@/views/ReportAbsenceRequestsView.vue'),
                meta: {disableOnMobileWidths: true},
                name: 'reportAbsenceRequestsView',
            },
            {
                path: 'report/rimborsi-spese',
                component: () => import('@/views/ReportExpensesView.vue'),
                meta: {disableOnMobileWidths: true},
                name: 'reportExpensesView',
            },
            {
                path: 'report/costo-aziendale',
                component: () => import('@/views/ReportBusinessCostView.vue'),
                meta: {disableOnMobileWidths: true},
                name: 'reportBusinessCostView',
            },
            {
                path: 'report/tfr-azienda',
                component: () => import('@/views/ReportCompanyTfrView.vue'),
                meta: {disableOnMobileWidths: true},
                name: 'reportCompanyTfrView',
            },
            {
                path: 'report/export-dati',
                component: () => import('@/views/ReportDataExportView.vue'),
                meta: {disableOnMobileWidths: true},
                name: 'reportDataExportView',
            },
            {
                path: 'documenti',
                component: () => import('@/views/DocumentsView.vue'),
                name: 'documentsView',
            },
            {
                path: 'rimborsi-spese/:tab(tab-da-approvare|tab-in-approvazione-manager|tab-approvate|tab-rifiutate)',
                component: () => import('@/features/expense/views/ExpensesView.vue'),
                name: 'expensesViewWithTab',
            },
            {
                path: 'rimborsi-spese',
                redirect: to => ({path: `${to.path}/tab-da-approvare`, replace: true}),
                name: 'expensesView',
            },
            {
                path: 'agenda',
                component: () => import('@/views/AgendaItemsView.vue'),
                name: 'agendaItemsView',
            },
            {
                path: 'sicurezza/:tab(presentazione|tab-sicurezza|tab-ruoli-e-addetti|tab-visite-mediche)',
                component: () => import('@/features/safety-and-health/views/SafetyAndHealthView.vue'),
                name: 'safetyAndHealthViewWithTab',
            },
            {
                path: 'sicurezza',
                redirect: to => ({path: `${to.path}/presentazione`, replace: true}),
                name: 'safetyAndHealthView',
            },
            {
                path: 'impostazioni',
                component: () => import('@/views/SettingsView.vue'),
                name: 'settingsView',
            },
            {
                path: 'impostazioni-azienda/general',
                component: () => import('@/views/SettingsCompanyGeneralView.vue'),
                name: 'settingsCompanyGeneralView',
            },
            {
                path: 'impostazioni-azienda/orario-lavoro-festivita',
                component: () => import('@/views/SettingsCompanyWorkingHours.vue'),
                name: 'settingsCompanyWorkingHoursView',
            },
            {
                path: 'impostazioni-azienda',
                component: () => import('@/views/SettingsCompanyView.vue'),
                name: 'settingsCompanyView',
            },
            {
                path: 'impostazioni-azienda/accessi-personale',
                component: () => import('@/views/SettingsCompanyEmployeeRegistrationView.vue'),
                name: 'settingsCompanyEmployeeRegistrationView',
            },
            {
                path: 'impostazioni-azienda/accessi-admin',
                component: () => import('@/views/SettingsCompanyAdminRegistrationView.vue'),
                name: 'settingsCompanyAdminRegistrationView',
            },
            {
                path: 'impostazioni-azienda/campi-personalizzati',
                component: () => import('@/views/SettingsCustomFieldView.vue'),
                name: 'settingsCustomFieldView',
            },
            {
                path: 'impostazioni-azienda/manager',
                component: () => import('@/views/SettingsCompanyManagerView.vue'),
                name: 'settingsCompanyManagerView',
            },
            {
                path: 'impostazioni-azienda/policy-buoni-pasto/:policyId(\\d+|nuova)',
                component: () => import('@/views/CompanyMealVoucherPolicyView.vue'),
                name: 'companyMealVoucherPolicyView',
            },
            {
                // Dynamically building
                path: `impostazioni-azienda/policy-:policyCategory(${ROUTER_KEYS.join('|')})/:policyId(\\d+|nuova)`,
                component: () => import('@/features/company-policy/views/CompanyPolicyView.vue'),
                name: 'companyPolicyView',
            },
            {
                path: 'impostazioni-azienda/pagamenti',
                component: () => import('@/views/SettingsCompanyPaymentsView.vue'),
                name: 'settingsCompanyPaymentsView',
            },
            {
                path: 'impostazioni-azienda/sicurezza',
                component: () => import('@/features/safety-and-health/views/SettingsCompanySafetyView.vue'),
                name: 'settingsCompanySafetyView',
            },
            {
                path: 'costo-assunzione',
                component: () => import('@/views/HiringCostSimulatorView.vue'),
                name: 'hiringCostSimulatorView',
            },
            {
                path: 'noleggio-device',
                component: () => import('@/views/DeviceRentalView.vue'),
                meta: {disableOnMobileWidths: true},
                name: 'deviceRentalView',
            },
            {
                path: 'video-tutorial',
                component: () => import('@/features/video-tutorial/views/VideoTutorialView.vue'),
                name: 'videoTutorialView',
            },
            {
                path: ':pathMatch(.*)*',
                redirect: () => '/404',
            },
        ],
    },
];

const router = createRouter({
    scrollBehavior,
    history: createWebHashHistory(),
    routes,
});

router.beforeEach(async (to, from) => {
    for (const handler of [beforeEachHandler, onboardingBeforeEachHandler]) {
        const ret = await handler(to, from, api, useSpaStore, settings.JET_ROUTE_ENTITY_NAME);
        if (typeof ret !== 'undefined') {
            return ret;
        }
    }
});

router.afterEach(afterEachHandler);

router.onError(onError);

export default router;
