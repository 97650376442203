import {inject} from 'vue';
import {useQuery} from '@tanstack/vue-query';

export const useAbsenceKindMandatorySettingsQuery = () => {
    const {$api} = inject('jet');

    return useQuery({
        queryKey: ['getAbsenceKindMandatorySettings'],
        queryFn: async () => {
            const response = await $api.absenceRequestKindSettings.getMandatorySettings().catch($api.end);

            return response?.data ?? {};
        },
        placeholderData: {},
    });
};
