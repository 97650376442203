<script setup>
    import {ONBOARDING_TASK_CODENAME, ONBOARDING_TASK_STATUS} from '@jetCommon/constants/onboarding.js';
    import {isoDateToIta} from '@jetCommon/helpers/date.js';
    import {useOnboarding} from '@/features/onboarding/composables/onboarding.js';

    import JetDatePicker from '@jetDS/components/JetDatePicker.vue';
    import JetForm from '@jetDS/components/JetForm.vue';
    import JetFormItem from '@jetDS/components/JetFormItem.vue';
    import OnboardingTaskItem from '@/features/onboarding/components/tasks/base/OnboardingTaskItem.vue';

    const {$api} = inject('jet');
    const {fetchAll, taskByCodename} = useOnboarding();
    const task = computed(() => taskByCodename.value[ONBOARDING_TASK_CODENAME.CONSULTANT_CANCELLATION_DATE]);

    const formRef = ref(null);
    const formData = reactive({
        cancellation_date: null,
    });

    const formRules = reactive({
        cancellation_date: [{required: true, message: 'Campo obbligatorio', trigger: 'blur'}],
    });

    const metaText = computed(() => {
        return task.value.status === ONBOARDING_TASK_STATUS.DONE && task.value?.submitted_data?.cancellation_date
            ? isoDateToIta(task.value.submitted_data.cancellation_date)
            : null;
    });

    const actions = computed(() => {
        const compileForm = {
            main: true,
            text: 'Inserisci data',
            dialog: {
                title: 'Disdici il tuo consulente',
                name: 'main',
                confirm_button_text: 'Comunica data',
                async confirm_callback() {
                    const valid = await formRef.value
                        .getElFormRef()
                        .validate()
                        .catch(() => false);
                    if (valid) {
                        $api.companyOnboardingTasks.submitData(task.value.id, formData).then(fetchAll).catch($api.end);
                    }
                },
            },
        };

        return task.value.status === ONBOARDING_TASK_STATUS.TODO ? [compileForm] : [];
    });
</script>

<template>
    <OnboardingTaskItem
        :actions="actions"
        :codename="ONBOARDING_TASK_CODENAME.CONSULTANT_CANCELLATION_DATE"
        description="Comunicaci data da cui sarà ufficiale la disdetta con il vecchio consulente."
        :meta-text="metaText"
        title="Disdici il tuo consulente">
        <template #dialogMainBody>
            <p>
                Inserisci la data in cui hai notificato al vecchio consulente la disdetta del vostro rapporto. Se non te
                la ricordi, inserisci la data odierna.
            </p>

            <JetForm ref="formRef" :rules="formRules" :model="formData">
                <JetFormItem prop="cancellation_date" label="Data">
                    <JetDatePicker v-model="formData.cancellation_date" full-width />
                </JetFormItem>
            </JetForm>
        </template>
    </OnboardingTaskItem>
</template>
