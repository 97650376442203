<script setup>
    import {
        TARGET_FOR_ABSENCE_KIND,
        TARGET_LABEL_FOR_ABSENCE_KIND,
        ABSENCE_KIND_GROUPS,
        ABSENCE_KIND_GROUP_LABEL,
    } from '@/features/company-policy/absence/constants';
    import {storeToRefs} from 'pinia';
    import {useAbsenceKindMandatorySettingsQuery} from '@jetCommon/queries/useAbsenceKindMandatorySettingsQuery';
    import {useAbsencePolicyOptions} from '@/features/company-policy/absence/composables/absence-policy-options';
    import {useCompanyAbsenceRequestKind} from '@jetCommon/composables/company-absence-request-kind.js';
    import {useContractorAddon} from '@jetCommon/composables/contractor-addon.js';

    import JetCallout from '@jetDS/components/JetCallout.vue';
    import JetDivider from '@jetDS/components/JetDivider.vue';
    import JetForm from '@jetDS/components/JetForm.vue';
    import JetFormItem from '@jetDS/components/JetFormItem.vue';
    import JetInfoLine from '@jetDS/components/JetInfoLine.vue';
    import JetInfoLineGroup from '@jetDS/components/JetInfoLineGroup.vue';
    import JetInput from '@jetDS/components/JetInput.vue';
    import JetRadioButton from '@jetDS/components/JetRadioButton.vue';
    import JetRadioGroup from '@jetDS/components/JetRadioGroup.vue';
    import JetSelect from '@jetDS/components/JetSelect.vue';
    import JetSwitch from '@jetDS/components/JetSwitch.vue';
    import JetTable from '@jetDS/components/JetTable.vue';
    import JetTableColumn from '@jetDS/components/JetTableColumn.vue';

    const props = defineProps({
        policy: {
            type: Object,
            required: true,
        },
    });

    const emit = defineEmits(['updated-form']);
    const {$spaStore} = inject('jet');
    const {companyId} = storeToRefs($spaStore);
    const {attachmentChoices} = useCompanyAbsenceRequestKind(companyId);
    const {contractorsAddonEnabled} = useContractorAddon();

    const {data: mandatorySettings} = useAbsenceKindMandatorySettingsQuery();

    const formRef = ref(null);
    const formData = ref({
        ...props.policy,
    });
    const {
        absenceOptionsGrouped,
        onUpdateOption,
        updatedOptions,
        initialActiveOptions,
        initialApprovalRequiredOptions,
    } = useAbsencePolicyOptions(props.policy.id);

    // True if at least one absence kind switched to requires_approval = false
    const hasNewAutoApprovalOptions = computed(() => {
        return updatedOptions.value.some(
            option => !option.requires_approval && initialApprovalRequiredOptions.value.includes(option.codename),
        );
    });

    // True if the at least one absence kinds switched to is_active = False
    const hasNewDisabledOptions = computed(() => {
        return updatedOptions.value.some(
            option => !option.is_active && initialActiveOptions.value.includes(option.codename),
        );
    });

    watch(
        [formData, updatedOptions],
        () => {
            const data = {
                ...formData.value,
            };

            // Only send updated options to improve performance
            if (updatedOptions.value.length > 0) {
                data.kind_configurations = updatedOptions.value;
            }

            emit('updated-form', data);
        },
        {deep: true},
    );

    const formRules = {
        name: [{required: true, message: 'Inserire il titolo della policy', trigger: 'change'}],
    };

    function isFormValid() {
        return formRef.value.getElFormRef().validate();
    }

    // Show a warning if at least one absence kind requires approval and is targeted to externals
    const showApprovalForExternalsWarning = computed(() => {
        if (!absenceOptionsGrouped.value) {
            return false;
        }
        return absenceOptionsGrouped.value[ABSENCE_KIND_GROUPS.HOLIDAYS_AND_PERMITS].some(
            choice =>
                TARGET_FOR_ABSENCE_KIND[choice.codename] === TARGET_LABEL_FOR_ABSENCE_KIND.EXTERNAL &&
                choice.requires_approval,
        );
    });

    defineExpose({
        isFormValid,
    });
</script>

<template>
    <JetCallout v-if="showApprovalForExternalsWarning" plain type="warning" class="mb-4">
        <b>Attenzione!</b>
        <p class="primary">
            Secondo la normativa vigente l'assenza di
            {{
                contractorsAddonEnabled ? ' collaboratori e professionisti autonomi ' : ' tirocinanti e collaboratori '
            }}non può essere soggetta ad approvazione da parte di un manager o di altri referenti in azienda. Ti
            consigliamo di modificare questa impostazione.&nbsp;
            <template v-if="contractorsAddonEnabled">
                <a
                    href="https://help.jethr.com/it/articles/10513258-falsa-partita-iva-quando-rischi-che-il-lavoratore-autonomo-sia-considerato-un-dipendente"
                    target="_blank">
                    Leggi l'articolo
                </a>
                per maggiori dettagli.
            </template>
        </p>
    </JetCallout>
    <JetCallout v-if="hasNewAutoApprovalOptions" plain type="warning" class="mb-4">
        <b>Attenzione!</b>
        <p class="primary">
            Le richieste ancora in approvazione inserite in precedenza dovranno essere approvate manualmente.
        </p>
    </JetCallout>
    <JetCallout v-if="hasNewDisabledOptions" plain type="warning">
        <b>Attenzione!</b>
        <p class="primary">
            Le richieste inserite in precedenza rimarranno valide e dovranno essere gestite manualmente.
        </p>
    </JetCallout>
    <JetInfoLineGroup>
        <JetForm ref="formRef" :rules="formRules" :model="formData" label-position="top">
            <JetInfoLine
                label="Titolo della policy"
                action-label=""
                description="Assegna un titolo alla policy per distinguerla facilmente dalle altre">
                <JetFormItem prop="name">
                    <JetInput v-model="formData.name" class="TextInput" />
                </JetFormItem>
            </JetInfoLine>
        </JetForm>
        <JetDivider class="mt-0" />
        <template v-if="absenceOptionsGrouped">
            <div v-for="[group, options] in Object.entries(absenceOptionsGrouped)" :key="group">
                <h3 class="regular medium base py-3">{{ ABSENCE_KIND_GROUP_LABEL[group] }}</h3>
                <JetTable :data="options" class="mb-4">
                    <JetTableColumn class-name="primary" label="Tipologia" skeleton-type="text">
                        <template #default="{row}">
                            <div class="primary medium">{{ row.name }}</div>
                            <div class="secondary xs">
                                Applicabile
                                {{
                                    !row.is_custom
                                        ? TARGET_FOR_ABSENCE_KIND[row.codename]
                                        : TARGET_LABEL_FOR_ABSENCE_KIND.INTERNAL
                                }}
                            </div>
                        </template>
                    </JetTableColumn>
                    <JetTableColumn class-name="primary" label="Approvazione" skeleton-type="text">
                        <template #default="{row}">
                            <JetRadioGroup
                                v-model="row.requires_approval"
                                @update:model-value="() => onUpdateOption(row)">
                                <JetRadioButton
                                    :disabled="new Set(mandatorySettings?.not_requires_approval).has(row.codename)"
                                    :label="true">
                                    Richiesta
                                </JetRadioButton>
                                <JetRadioButton :label="false">Non richiesta</JetRadioButton>
                            </JetRadioGroup>
                        </template>
                    </JetTableColumn>
                    <JetTableColumn class-name="primary" label="Giustificativo" skeleton-type="text">
                        <template #default="{row}">
                            <JetSelect
                                v-model="row.attachment_option"
                                :options="attachmentChoices"
                                :disabled="new Set(mandatorySettings?.attachment_mandatory).has(row.codename)"
                                @update:model-value="() => onUpdateOption(row)" />
                        </template>
                    </JetTableColumn>
                    <JetTableColumn width="82" class-name="primary" label="Attivo" skeleton-type="text">
                        <template #default="{row}">
                            <JetSwitch v-model="row.is_active" @update:model-value="() => onUpdateOption(row)" />
                        </template>
                    </JetTableColumn>
                </JetTable>
                <JetCallout
                    v-if="group === ABSENCE_KIND_GROUPS.HOLIDAYS_AND_PERMITS"
                    class="mb-4"
                    plain
                    icon-name="star"
                    type="info">
                    Hai bisogno di aggiungere altre tipologie di presenza o assenza aziendale? Scrivici e ne gestiremo
                    assieme la configurazione.
                </JetCallout>
            </div>
        </template>
    </JetInfoLineGroup>
</template>

<style scoped lang="scss">
    .TextInput {
        max-width: 320px;
    }

    :deep(.el-form-item__error) {
        position: absolute;
    }
</style>
