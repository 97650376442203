<script setup>
    import 'element-plus/es/components/table/style/index';
    import {ElTable, ElTableColumn} from 'element-plus';

    const props = defineProps({
        clickableRows: {
            type: Boolean,
            default: true,
        },
        hoverEffect: {
            type: Boolean,
            default: true,
        },
        hideBottomBorder: {
            type: Boolean,
            default: false,
        },
        rowClassName: {
            type: [Function, String],
            default: '',
        },
        rowHeight: {
            type: [Number, String],
            default: null,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        // Before enabling multi selection, ensure that the each row in the table has an `id` property
        multiSelection: {
            type: [Boolean],
            default: false,
        },
        selectable: {
            type: Function,
            default: () => true,
        },
        height: {
            type: [Number, String],
            default: null,
        },
        rowDisabled: {
            type: Function,
            default: () => null,
        },
        emptyFullWidth: {
            type: Boolean,
            default: false,
        },
    });

    const emit = defineEmits(['columns-initialized']);

    const elTableRef = ref(null);
    const scrollBarRef = ref(null);
    const selectedRowsIds = computed(() => {
        if (!elTableRef.value || !props.multiSelection) return [];

        return elTableRef.value.getSelectionRows().map(r => r.id);
    });

    const tableClass = computed(() => ({
        'jet-table': true,
        'jet-table--hide-bottom-border': props.hideBottomBorder,
        'jet-table--empty-full-width': props.emptyFullWidth,
        loading: props.isLoading,
    }));
    const rowStyle = computed(() => {
        if (!props.rowHeight) {
            return {};
        }

        return {
            '--jet-table-row-height': typeof props.rowHeight === 'number' ? `${props.rowHeight}px` : props.rowHeight,
        };
    });

    function rowIsSelected(row) {
        if (!row) return false;

        return selectedRowsIds.value.includes(row.id);
    }
    function getRowClassName(row) {
        let baseClassName = props.clickableRows ? 'jet-table-row' : 'jet-table-row jet-table-row--non-clickable';

        if (!props.hoverEffect) {
            baseClassName += ' jet-table-row--no-hover';
        }

        if (props.multiSelection && rowIsSelected(row.row)) {
            baseClassName += ' jet-table-row--selected';
        }

        if (props.rowDisabled && props.rowDisabled(row.row)) {
            baseClassName += ' jet-table-row--deactivated pointer-events-auto';
        }

        const propClassName = typeof props.rowClassName === 'function' ? props.rowClassName(row) : props.rowClassName;
        return `${baseClassName} ${propClassName}`;
    }

    const sort = (prop, order) => {
        elTableRef.value.sort(prop, order);
    };

    const setCurrentRow = row => {
        elTableRef.value.setCurrentRow(row);
    };

    const toggleRowSelection = (row, selected) => {
        // When selecting I override the current row behavior
        setCurrentRow(null);
        elTableRef.value.toggleRowSelection(row, selected);
    };

    const clearSelection = () => {
        elTableRef.value.clearSelection();
    };

    function onRowClick(row) {
        // If at least one row is selected, allow selection by simply clicking on rows
        if (!props.multiSelection || selectedRowsIds.value.length === 0) return;

        toggleRowSelection(row, !rowIsSelected(row));
    }

    onMounted(() => {
        watch(
            elTableRef.value.store.states.columns,
            // eslint-disable-next-line wrap-iife
            (function () {
                // `columns` may change, do emit only once
                let done = false;
                return function () {
                    if (!done) {
                        done = true;
                        emit('columns-initialized');
                    }
                };
            })(),
        );

        scrollBarRef.value = elTableRef.value.scrollBarRef;
    });

    defineExpose({sort, toggleRowSelection, clearSelection, setCurrentRow, scrollBarRef});
</script>

<template>
    <ElTable
        ref="elTableRef"
        :class="tableClass"
        header-cell-class-name="jet-table-head"
        :row-class-name="getRowClassName"
        :row-style="rowStyle"
        :height="height"
        @row-click="onRowClick">
        <!--
          Expose all slots of the third-party component:
          https://mokkapps.de/vue-tips/expose-slots-from-a-child-component/
        -->
        <template v-for="(_, name) in $slots" :key="name" #[name]="slotProps">
            <ElTableColumn
                v-if="multiSelection && name === 'default'"
                type="selection"
                :selectable="selectable"
                width="44"
                reserve-selection />
            <slot :name="name" v-bind="slotProps || {}" />
        </template>
    </ElTable>
</template>

<style lang="scss" scoped>
    .jet-table {
        width: 100%;
    }

    :deep(tr.jet-table-row) {
        font-size: var(--el-font-size-base);
        cursor: pointer;
    }

    :deep(tr.jet-table-row.current-row:hover > td) {
        // if the row is current row no hover effect
        background-color: var(--jet-color-lime-light-8) !important;
    }

    :deep(tr.jet-table-row--non-clickable) {
        font-size: var(--el-font-size-base);
        cursor: default;
    }

    :deep(tr.jet-table-row--no-hover):hover > td {
        background-color: initial !important;
    }

    :deep(tr.jet-table-row--selected) {
        background-color: var(--jet-color-lime-light-8);
    }

    :deep(tr.jet-table-row--selected:hover > td) {
        // Same as for current row
        background-color: var(--jet-color-lime-light-8) !important;
    }

    :deep(tr.jet-table-row--deactivated) {
        .cell {
            opacity: 0.3;
        }
    }

    :deep(th.jet-table-head) {
        font-size: var(--el-font-size-base);
        font-weight: var(--jet-font-weight-semibold);
        padding: var(--jet-sp-3) var(--jet-sp-0);
        &.ascending,
        &.descending {
            color: var(--jet-text-color-primary);
            background-color: var(--jet-fill-color-dark);
        }
    }

    :deep(th.jet-table-head:not(:last-child)) {
        border-right: var(--jet-table-header-border-right-width) solid var(--el-border-color) !important;
    }

    :deep(.jet-table-main-col) {
        font-weight: var(--jet-font-weight-semibold);
    }

    :deep(.el-table__cell) {
        height: var(--jet-table-row-height);
        padding: var(--jet-sp-4) var(--jet-sp-0);
        word-break: break-word;
    }

    :deep(.cell) {
        padding: var(--jet-sp-0) var(--jet-sp-4);
        word-break: break-word;
        &:has(.el-table__expand-icon) {
            display: flex;
            align-items: center;
        }

        &:has(.el-table__indent) {
            display: flex;

            .el-table__placeholder {
                display: none;
            }
        }
    }

    :deep(.el-checkbox__input.is-indeterminate .el-checkbox__inner::before) {
        width: var(--jet-checkbox-indet-icon-width);
        height: var(--jet-checkbox-indet-icon-height);
        transform: none;
        left: var(--jet-checkbox-indet-icon-pos-left);
    }

    .loading {
        :deep(.el-table__body) {
            mask-image: -webkit-gradient(linear, left top, left bottom, from(rgb(0 0 0 / 100%)), to(rgb(0 0 0 / 0%)));
        }
    }

    // Summary row
    :deep(.el-table__footer .el-table__cell) {
        background-color: var(--jet-fill-color-darker);
        color: var(--el-text-color-secondary);
        &:not(:last-child) {
            border-right: var(--jet-table-header-border-right-width) solid var(--jet-border-color-darker);
        }
    }

    // Summary row between header and body
    :deep(.el-table__body-wrapper) {
        order: 1;
    }
    :deep(.el-table__footer-wrapper) {
        border-top: 0;
    }

    :deep(.el-table__expand-icon) {
        height: auto !important;
        margin-right: var(--jet-sp-4) !important;
        & > .el-icon {
            font-size: var(--el-font-size-large);
        }
    }

    // Hide bottom border
    .jet-table--hide-bottom-border {
        :deep(.el-table__inner-wrapper::before) {
            display: none;
        }
        :deep(.el-table__row:last-child .el-table__cell) {
            border-bottom-color: transparent;
        }
    }

    .jet-table--empty-full-width {
        :deep(.el-table__empty-block) {
            box-sizing: border-box;
        }
        :deep(.el-table__empty-text) {
            width: 100%;
        }
    }
</style>
