const CURRENCY = {
    AFN: 'AFN',
    ALL: 'ALL',
    DZD: 'DZD',
    AOA: 'AOA',
    XCD: 'XCD',
    ANG: 'ANG',
    SAR: 'SAR',
    ARS: 'ARS',
    AMD: 'AMD',
    AWG: 'AWG',
    AUD: 'AUD',
    AZN: 'AZN',
    BSD: 'BSD',
    BHD: 'BHD',
    BDT: 'BDT',
    BBD: 'BBD',
    BZD: 'BZD',
    XOF: 'XOF',
    BMD: 'BMD',
    BTN: 'BTN',
    BYN: 'BYN',
    BOB: 'BOB',
    BAM: 'BAM',
    BWP: 'BWP',
    BRL: 'BRL',
    BND: 'BND',
    BGN: 'BGN',
    BIF: 'BIF',
    KHR: 'KHR',
    XAF: 'XAF',
    CAD: 'CAD',
    CVE: 'CVE',
    KYD: 'KYD',
    CZK: 'CZK',
    CLP: 'CLP',
    CNY: 'CNY',
    COP: 'COP',
    KMF: 'KMF',
    CDF: 'CDF',
    KPW: 'KPW',
    KRW: 'KRW',
    CRC: 'CRC',
    CUP: 'CUP',
    DKK: 'DKK',
    DOP: 'DOP',
    EGP: 'EGP',
    EUR: 'EUR',
    SVC: 'SVC',
    AED: 'AED',
    ERN: 'ERN',
    SZL: 'SZL',
    ETB: 'ETB',
    FKP: 'FKP',
    FJD: 'FJD',
    PHP: 'PHP',
    XDR: 'XDR',
    GMD: 'GMD',
    GEL: 'GEL',
    GHS: 'GHS',
    JMD: 'JMD',
    JPY: 'JPY',
    GIP: 'GIP',
    DJF: 'DJF',
    JOD: 'JOD',
    GTQ: 'GTQ',
    GNF: 'GNF',
    GYD: 'GYD',
    HTG: 'HTG',
    HNL: 'HNL',
    HKD: 'HKD',
    INR: 'INR',
    IDR: 'IDR',
    IRR: 'IRR',
    IQD: 'IQD',
    ISK: 'ISK',
    ILS: 'ILS',
    KZT: 'KZT',
    KES: 'KES',
    KGS: 'KGS',
    KWD: 'KWD',
    LAK: 'LAK',
    LSL: 'LSL',
    LBP: 'LBP',
    LRD: 'LRD',
    LYD: 'LYD',
    MOP: 'MOP',
    MKD: 'MKD',
    MGA: 'MGA',
    MWK: 'MWK',
    MYR: 'MYR',
    MVR: 'MVR',
    MAD: 'MAD',
    MRU: 'MRU',
    MUR: 'MUR',
    MXN: 'MXN',
    MDL: 'MDL',
    MNT: 'MNT',
    MZN: 'MZN',
    MMK: 'MMK',
    NAD: 'NAD',
    NPR: 'NPR',
    NIO: 'NIO',
    NGN: 'NGN',
    NOK: 'NOK',
    NZD: 'NZD',
    OMR: 'OMR',
    PKR: 'PKR',
    PAB: 'PAB',
    PGK: 'PGK',
    PYG: 'PYG',
    PEN: 'PEN',
    XPF: 'XPF',
    PLN: 'PLN',
    QAR: 'QAR',
    GBP: 'GBP',
    RON: 'RON',
    RUB: 'RUB',
    RWF: 'RWF',
    SBD: 'SBD',
    WST: 'WST',
    SHP: 'SHP',
    STN: 'STN',
    RSD: 'RSD',
    SCR: 'SCR',
    SLE: 'SLE',
    SGD: 'SGD',
    SYP: 'SYP',
    SOS: 'SOS',
    LKR: 'LKR',
    USD: 'USD',
    ZAR: 'ZAR',
    SSP: 'SSP',
    SDG: 'SDG',
    SRD: 'SRD',
    SEK: 'SEK',
    CHF: 'CHF',
    TJS: 'TJS',
    TWD: 'TWD',
    TZS: 'TZS',
    THB: 'THB',
    TOP: 'TOP',
    TTD: 'TTD',
    TND: 'TND',
    TRY: 'TRY',
    TMT: 'TMT',
    UAH: 'UAH',
    UGX: 'UGX',
    HUF: 'HUF',
    UYU: 'UYU',
    UZS: 'UZS',
    VUV: 'VUV',
    VES: 'VES',
    VND: 'VND',
    YER: 'YER',
    ZMW: 'ZMW',
    ZWG: 'ZWG',
};

const CURRENCY_LABEL = {
    AFN: 'Afghani',
    ALL: 'Lek',
    DZD: 'Dinaro Algerino',
    AOA: 'Readjustado Kwanza',
    XCD: 'Dollaro Caraibi Est',
    ANG: 'Fiorino Antille Olandesi',
    SAR: 'Riyal Saudita',
    ARS: 'Peso Argentina',
    AMD: 'Dram Armenia',
    AWG: 'Fiorino Aruba',
    AUD: 'Dollaro Australiano',
    AZN: 'Manat Azerbaigian (nuovo)',
    BSD: 'Dollaro Bahama',
    BHD: 'Dinaro Bahrain',
    BDT: 'Taka',
    BBD: 'Dollaro Barbados',
    BZD: 'Dollaro Belize',
    XOF: 'Franco CFA',
    BMD: 'Dollaro Bermuda',
    BTN: 'Ngultrum',
    BYN: 'Rublo Bielorussia (nuovo)',
    BOB: 'Boliviano',
    BAM: 'Marco Convertibile',
    BWP: 'Pula',
    BRL: 'Real',
    BND: 'Dollaro Brunei',
    BGN: 'Nuovo Lev Bulgaria',
    BIF: 'Franco Burundi',
    KHR: 'Riel Kampuchea',
    XAF: 'Franco CFA',
    CAD: 'Dollaro Canadese',
    CVE: 'Escudo Capo Verde',
    KYD: 'Dollaro Isole Cayman',
    CZK: 'Corona Ceca',
    CLP: 'Peso Cileno',
    CNY: 'Renminbi(Yuan)',
    COP: 'Peso Colombiano',
    KMF: 'Franco Isole Comore',
    CDF: 'Franco Congolese',
    KPW: 'Won Nord',
    KRW: 'Won Sud',
    CRC: 'Colon Costa Rica',
    CUP: 'Peso Cubano',
    DKK: 'Corona Danese',
    DOP: 'Peso Dominicano',
    EGP: 'Lira Egiziana',
    EUR: 'Euro',
    SVC: 'Colon Salvadoregno',
    AED: 'Dirham Emirati Arabi',
    ERN: 'Nakfa',
    SZL: 'Lilangeni',
    ETB: 'Birr',
    FKP: 'Sterlina Falkland',
    FJD: 'Dollaro Fiji',
    PHP: 'Peso Filippino',
    XDR: 'DSP',
    GMD: 'Dalasi',
    GEL: 'Lari',
    GHS: 'Nuovo Cedi Ghana',
    JMD: 'Dollaro Giamaicano',
    JPY: 'Yen Giapponese',
    GIP: 'Sterlina Gibilterra',
    DJF: 'Franco Gibuti',
    JOD: 'Dinaro Giordano',
    GTQ: 'Quetzal',
    GNF: 'Franco Guineano',
    GYD: 'Dollaro Guyana',
    HTG: 'Gourde',
    HNL: 'Lempira',
    HKD: 'Dollaro Hong Kong',
    INR: 'Rupia Indiana',
    IDR: 'Rupia Indonesiana',
    IRR: 'Rial Iraniano',
    IQD: 'Dinaro Iracheno',
    ISK: 'Corona Islanda',
    ILS: 'Shekel',
    KZT: 'Tenge Kazakistan',
    KES: 'Scellino Keniota',
    KGS: 'Som',
    KWD: 'Dinaro Kuwait',
    LAK: 'Lao Kip',
    LSL: 'Loti',
    LBP: 'Lira Libanese',
    LRD: 'Dollaro Liberia',
    LYD: 'Dinaro Libico',
    MOP: 'Pataca',
    MKD: 'Dinaro Macedonia',
    MGA: 'Ariary',
    MWK: 'Kwacha Malawi',
    MYR: 'Ringgit',
    MVR: 'Rufiyaa',
    MAD: 'Dirham Marocco',
    MRU: 'Ouguiya',
    MUR: 'Rupia Mauritius',
    MXN: 'Peso Messicano',
    MDL: 'Leu Moldavia',
    MNT: 'Tugrik',
    MZN: 'Nuovo Metical Mozambico',
    MMK: 'Kyat',
    NAD: 'Dollaro Namibia',
    NPR: 'Rupia Nepalese',
    NIO: 'Cordoba Oro',
    NGN: 'Naira',
    NOK: 'Corona Norvegese',
    NZD: 'Dollaro Neozelandese',
    OMR: 'Rial Oman',
    PKR: 'Rupia Pakistana',
    PAB: 'Balboa',
    PGK: 'Kina',
    PYG: 'Guarani',
    PEN: 'Nuevo Sol',
    XPF: 'Franco C.F.P.',
    PLN: 'Zloty',
    QAR: 'Riyal Qatar',
    GBP: 'Sterlina Gran Bretagna',
    RON: 'Nuovo Leu',
    RUB: 'Rublo Russia',
    RWF: 'Franco Ruanda',
    SBD: 'Dollaro Isole Salomone',
    WST: 'Tala',
    SHP: 'Sterlina S.Elena',
    STN: 'Dobra',
    RSD: 'Dinaro Serbo',
    SCR: 'Rupia Seychelles',
    SLE: 'Leone',
    SGD: 'Dollaro Singapore',
    SYP: 'Lira Siriana',
    SOS: 'Scellino Somalo',
    LKR: 'Rupia Sri Lanka',
    USD: 'Dollaro USA',
    ZAR: 'Rand',
    SSP: 'Sterlina Sud Sudan',
    SDG: 'Sterlina Sudanese',
    SRD: 'Dollaro Suriname',
    SEK: 'Corona Svedese',
    CHF: 'Franco Svizzero',
    TJS: 'Somoni',
    TWD: 'Dollaro Taiwan',
    TZS: 'Scellino Tanzania',
    THB: 'Baht',
    TOP: 'Pa Anga',
    TTD: 'Dollaro Trinidad eTobago',
    TND: 'Dinaro Tunisino',
    TRY: 'Lira Turchia (nuova)',
    TMT: 'Manat Turkmenistan (nuovo)',
    UAH: 'Hryvnia',
    UGX: 'Scellino Ugandese',
    HUF: 'Forint Ungherese',
    UYU: 'Peso Uruguaiano',
    UZS: 'Sum Uzbekistan',
    VUV: 'Vatu',
    VES: 'Bolivar Soberano',
    VND: 'Dong',
    YER: 'Rial',
    ZMW: 'Kwacha Zambia',
    ZWG: 'Dollaro Zimbabwese',
};

export {CURRENCY, CURRENCY_LABEL};
