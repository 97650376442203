<script setup>
    import JetButton from '@jetDS/components/JetButton.vue';
    import JetDialog from '@jetDS/components/JetDialog.vue';
    import JetInput from '@jetDS/components/JetInput.vue';

    const props = defineProps({
        title: {
            type: String,
            required: true,
        },
        options: {
            type: Array,
            required: true,
        },
        valueKey: {
            type: String,
            default: 'value',
        },
        labelKey: {
            type: String,
            default: 'display_name',
        },
    });

    const emit = defineEmits(['close', 'confirm']);

    const internalOptions = ref([]);

    function addEmptyOption() {
        internalOptions.value.push({
            [props.valueKey]: null,
            [props.labelKey]: '',
        });
    }

    function trashOption(value) {
        internalOptions.value = internalOptions.value.filter(option => option[props.labelKey] !== value);
        if (internalOptions.value.length === 0) {
            addEmptyOption();
        }
    }

    watch(
        () => props.options,
        () => {
            internalOptions.value = toRaw(props.options).map(item => ({...item}));
            if (internalOptions.value.length === 0) {
                addEmptyOption();
            }
        },
        {immediate: true},
    );

    const areLabelsCompliant = computed(() => {
        if (internalOptions.value.length === 0) {
            return true;
        }
        const allOptionsAreNotEmpty = internalOptions.value.every(option => option[props.labelKey] !== '');
        const allOptionsAreDifferent = internalOptions.value.every(
            (option, index, array) => array.findIndex(o => o[props.labelKey] === option[props.labelKey]) === index,
        );
        return (allOptionsAreNotEmpty || internalOptions.value.length === 1) && allOptionsAreDifferent;
    });

    const areOptionsDeleted = computed(() => {
        // This check if some of the initial options are not present in the internalOptions
        return !props.options.every(option =>
            internalOptions.value.some(internalOption => internalOption[props.valueKey] === option[props.valueKey]),
        );
    });

    const areOptionsRenamed = computed(() => {
        // This check if some of the initial options have been renamed
        return props.options.some(option =>
            internalOptions.value.some(
                internalOption =>
                    internalOption[props.valueKey] === option[props.valueKey] &&
                    internalOption[props.valueKey] !== null &&
                    internalOption[props.labelKey] !== option[props.labelKey],
            ),
        );
    });

    function handleCancelClick() {
        emit('close');
    }

    function handleConfirmClick() {
        if (internalOptions.value.length === 1 && internalOptions.value[0][props.labelKey] === '') {
            internalOptions.value = [];
        }
        emit('confirm', internalOptions.value);
    }
</script>

<template>
    <JetDialog
        :title="title"
        show
        :before-close="handleCancelClick"
        destroy-on-close
        :submit-promise="handleConfirmClick"
        :disable-submit="!areLabelsCompliant">
        <template #body>
            <template v-for="option in internalOptions" :key="option[valueKey]">
                <div class="EditableInputOptions mb-2">
                    <JetInput v-model="option[labelKey]" type="text" />
                    <JetButton icon-name="trash" @click="trashOption(option[labelKey])" />
                </div>
            </template>
            <JetButton text class="bold base" :disabled="!areLabelsCompliant" @click="addEmptyOption">
                Aggiungi opzione
            </JetButton>
            <slot v-if="areOptionsDeleted" name="optionsDeleted"></slot>
            <slot v-if="areOptionsRenamed" name="optionsRenamed"></slot>
        </template>
    </JetDialog>
</template>

<style scoped lang="scss">
    .EditableInputOptions {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 8px;
    }
</style>
